import axios from "axios";

const getDespachos = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const cantidadSegundosDespacho = localStorage.getItem("cantidadSegundosDespacho");
  // const serialNumberKeyClover = localStorage.getItem("serialNumberKeyClover");

  const userData = {
    nic: formattedNic,
    token: token,
    cantidadSegundosDespacho: cantidadSegundosDespacho,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Despachos/GetDespachosInformacion`, userData);
  return response;
};

const despachos = {
  getDespachos,
};

export default despachos;
