import axios from "axios";

const getChoferes = async searchTerm => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  const userData = {
    nic: formattedNic,
    token: token,
    pageNumber: 1,
    searchString: searchTerm,
    idCliente: cliente.idCliente,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  console.log(userData);

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/choferes/getchoferes`, userData);
  return response;
};

const choferes = {
  getChoferes,
};

export default choferes;
