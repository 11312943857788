import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FormChoferConCliente from "../components/FormChoferConCliente";
import FormChoferSinCliente from "../components/FormChoferSinCliente";
import Choferes from "../api/choferes";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const ChoferesPage = () => {
  const theme = useTheme();
  const [choferes, setChoferes] = useState(null); // null significa que aún estamos cargando

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Choferes.getChoferes("");
        setChoferes(true);
      } catch (error) {
        setChoferes(false);
        console.error("Error al obtener los datos:", error);
        // apiErrorHandler(error, { stopFlow: true });
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <MenuSection />
      {choferes === null ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
        </Box>
      ) : choferes ? (
        <FormChoferConCliente />
      ) : (
        <FormChoferSinCliente />
      )}
    </div>
  );
};

export default ChoferesPage;
