// import React, { useState, useEffect } from "react";
// import { Box, Typography, Button } from "@mui/material";
// import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import QRCode from "qrcode.react";
// import axios from "axios";
// import MenuSection from "../components/MenuSection";
// import { useTheme } from "@mui/material/styles";

// const PagoConMercadopago = () => {
//   const totalProductosStored = parseFloat(localStorage.getItem("totalProductos")); // Cambia a parseFloat si es un número decimal
//   const [qrData, setQrData] = useState("");

//   const handleQR = async () => {
//     const requestData = {
//       external_reference: "12345",
//       title: "Product order",
//       notification_url: "https://www.yourserver.com",
//       total_amount: totalProductosStored,
//       items: [
//         {
//           sku_number: "A123K9191938",
//           category: "marketplace",
//           title: "Point Mini",
//           description: "This is the Point Mini",
//           unit_price: totalProductosStored,
//           quantity: 1,
//           unit_measure: "unit",
//           total_amount: totalProductosStored,
//         },
//       ],
//       cash_out: {
//         amount: 0,
//       },
//       description: "test",
//     };

//     const requestDataJSON = JSON.stringify(requestData);

//     try {
//       const response = await axios.post(
//         "${process.env.REACT_APP_API_URL}/instore/orders/qr/seller/collectors/75674413/pos/default/qrs",
//         requestDataJSON,
//         {
//           headers: {
//             Authorization: "Bearer APP_USR-834652141695968-083101-47cc84fd20391acd5cab46f3c2692746-75674413",
//             "Content-Type": "application/json",
//           },
//         },
//       );
//       setQrData(response.data.qr_data);
//     } catch (error) {
//       console.error("Error", error);
//     }
//   };

//   useEffect(() => {
//     handleQR();
//   }, []);

//   const handleCancel = () => {
//     window.history.back();
//   };

//   const handleClick = () => {
//     window.history.back();
//   };
//   const theme = useTheme();
//   return (
//     <>
//   <MenuSection/>
//       <Box
//         sx={{
//           position: "absolute",
//           backgroundColor: "white",
//           borderRadius: "8px",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//         <React.Fragment>
//           <Typography variant="h5" color="success" sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}>
//             Pago con QR
//           </Typography>
//           <Typography variant="body1" sx={{ marginBottom: 2, fontSize: 17 }}>
//             {"Monto: " + totalProductosStored}
//           </Typography>

//           {qrData && (
//             <div>
//               <QRCode value={qrData} size={200} />
//             </div>
//           )}
//           <Button sx={{ marginTop: 2, fontSize: 20, backgroundColor: theme.bandera.colorSecundario, fontWeight:"bold" }} variant="contained" onClick={handleCancel}>
//             Cancelar
//           </Button>
//         </React.Fragment>
//       </Box>
//     </>
//   );
// };

// export default PagoConMercadopago;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ConstructionIcon from "@mui/icons-material/Construction";
import MenuSection from "../components/MenuSection";

const PagoConMercadopago = () => {
  return (
    <>
      <MenuSection />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <ConstructionIcon style={{ fontSize: 100, color: "#3f51b5" }} />
        <Typography variant="h4" color="textSecondary" style={{ marginTop: 20 }}>
          En Construcción
        </Typography>
      </Box>
    </>
  );
};

export default PagoConMercadopago;
