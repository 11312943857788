import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LogoCaldenMobilePos from "../images/logoCaldenMobilePOSv2.png";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import DnsIcon from "@mui/icons-material/Dns";
import Conexion from "../api/conexion";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Footer from "../components/Footer";
import banderas from "../api/banderas";
import estacion from "../api/estacion";
import limiteAfip from "../api/limiteAfip";
import tarjetas from "../api/tarjetas";
import talonarioQR from "../api/talonarioQR";
import passEmpleado from "../api/empleado";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const Login = () => {
  const [showAlert, setShowAlert] = useState({
    open: false,
    type: "errorCamposVacios",
  });
  const [formattedNic, setFormattedNic] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [nic, setNic] = useState("");
  const [imageWidth, setImageWidth] = useState(400);
  const [cardMaxWidth, setCardMaxWidth] = useState(400);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serialNumberKeyClover, setSerialNumberKeyClover] = useState("");
  var estacionData = "";

  // Código a usar para la parte de Serial Key Clover
  useEffect(() => {
    const handleSerialNumberObtained = event => {
      const serialNumber = event.detail;
      setSerialNumberKeyClover(serialNumber);
      localStorage.setItem("serialNumberKeyClover", serialNumber);
    };

    // if (!localStorage.getItem("serialNumberKeyClover")) {
    //   localStorage.setItem("serialNumberKeyClover", "CLOVERKEYPRUEBA");
    // }

    // alert(localStorage.getItem("serialNumberKeyClover"));
    // Verifica si la función existe antes de intentar llamarla
    if (window.MyInterface && typeof window.MyInterface.obtenerSerialClover === "function") {
      try {
        window.MyInterface.obtenerSerialClover(); // Llama a la función para obtener el número de serie
      } catch (error) {
        console.error("Error al intentar obtener el número de serie Clover:", error);
      }
    } else {
      console.warn("La función obtenerSerialClover no está disponible.");
    }

    window.addEventListener("serialNumberObtained", handleSerialNumberObtained);

    return () => {
      window.removeEventListener("serialNumberObtained", handleSerialNumberObtained);
    };
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const toggleAlert = type => {
    setShowAlert({ open: true, type });
  };

  const handleNicChange = event => {
    setNic(event.target.value);
  };

  const handleUserChange = event => {
    setUser(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    if (password === "" || user === "" || nic === "") {
      toggleAlert("errorCamposVacios");
    } else {
      setIsLoading(true); // Set loading to true before making the request

      const formattedNic = Conexion.formatearNIC(nic);
      localStorage.setItem("formattedNic", formattedNic);
      setFormattedNic(formattedNic);
      const userData = { nombre: user, password: password, nic: formattedNic };
      try {
        const response = await Conexion.validarUsuario(userData);

        if (response.status === 500) {
          toggleAlert("errorUser");
        } else {
          if (rememberMe) {
            localStorage.setItem("user", response.data.nombre);
            localStorage.setItem("nic", nic);
          } else {
            localStorage.removeItem("user");
            localStorage.removeItem("nic");
          }
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", response.data.nombre);

          // Obtener el token es exitoso, ahora obtener las banderas
          try {
            const limite = await limiteAfip.getLimiteAfip(); // Obtain the limit data
            localStorage.setItem("limite", limite);

            const banderasData = await banderas.getBanderas();
            localStorage.setItem("bandera", banderasData.nombre.toLowerCase());

            estacionData = await estacion.getEstacion();
            var estacionDataJSON = JSON.stringify(estacionData);
            localStorage.setItem("estacionData", estacionDataJSON);

            const talonarioQRInfo = await talonarioQR.getIdTalonarioQR();
            localStorage.setItem("idTalonarioQR", talonarioQRInfo.data.idTerminalTarjeta);

            const passEmpleados = await passEmpleado.getEmpleado();
            localStorage.setItem("passEmpleados", JSON.stringify(passEmpleados.data));

            const tarjetasData = await tarjetas.getTarjetas();
            localStorage.setItem("tarjetas", JSON.stringify(tarjetasData));
            window.location.replace("/"); // Redirigir a la página de inicio
          } catch (error) {
            localStorage.removeItem("formattedNic");
            localStorage.removeItem("token");
            apiErrorHandler(error, { stopFlow: true });
          }
        }
      } catch (error) {
        localStorage.removeItem("formattedNic");
        localStorage.removeItem("token");
        toggleAlert("errorUser");
        setIsLoading(false);
      }
    }
  };

  const handleRememberMeChange = event => {
    setRememberMe(event.target.checked);
    if (!rememberMe) {
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.setItem("rememberMe", false);

      console.log("entro aca si NO quiero recordar");
    }
  };

  const updateDimensions = () => {
    const screenWidth = window.innerWidth;
    setImageWidth(screenWidth < 700 ? 300 : 250);
    setCardMaxWidth(screenWidth < 700 ? 400 : 400);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedNic = localStorage.getItem("nic");
    const storedRememberMe = localStorage.getItem("rememberMe");
    if (storedRememberMe === "true") {
      setRememberMe(true);
      setUser(storedUser);
      setNic(storedNic);
    }

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const cardStyle = {
    // margin: "0 auto",
    width: "100%",
    maxWidth: `${cardMaxWidth}px`,
    backgroundColor: "#009281",
    boxShadow: 0,
    padding: 1,
  };

  const inputStyle = {
    marginTop: -3,
    marginBottom: -60,
    // marginBottom: 0,
    backgroundColor: "white",
    borderRadius: 3,
  };

  const backgroundStyle = {
    backgroundColor: "#009281",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <div style={backgroundStyle}>
      <img src={LogoCaldenMobilePos} alt="Logo Calden Oil" style={{ width: "280px", height: "auto" }} />

      {isLoading ? (
        // Display CircularProgress while isLoading is true
        <CircularProgress sx={{ marginTop: "7rem" }} color="primary" />
      ) : (
        <Card sx={cardStyle}>
          <CardContent sx={{ marginTop: -6 }}>
            <h4 style={{ color: "#fff", paddingBottom: "2px" }}> NIC</h4>
            <TextField
              fullWidth
              type="text"
              value={nic}
              placeholder="Ingrese el NIC"
              sx={inputStyle}
              onChange={handleNicChange}
              InputProps={{
                startAdornment: <DnsIcon sx={{ marginRight: "5px", color: "#00427f" }} />,
              }}
            />
            <h4 style={{ color: "#fff", paddingBottom: "2px" }}> USUARIO</h4>
            <TextField
              fullWidth
              type="text"
              value={user}
              placeholder="Ingrese su usuario"
              sx={inputStyle}
              onChange={handleUserChange}
              InputProps={{
                startAdornment: <PersonIcon sx={{ marginRight: "5px", color: "#00427f" }} />,
              }}
            />
            <h4 style={{ color: "#fff", paddingBottom: "2px" }}>CONTRASEÑA</h4>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              placeholder="Ingrese su contraseña"
              onChange={handlePasswordChange}
              InputProps={{
                startAdornment: <KeyIcon sx={{ marginRight: "5px", color: "#00427f" }} />,
                endAdornment: (
                  <IconButton onClick={togglePasswordVisibility} sx={{ color: "#00427f" }}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{
                ...inputStyle,
                fontWeight: "bold",
              }}
            />

            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 15,
                  marginTop: 1,
                }}
              >
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  sx={{
                    "&.Mui-checked": {
                      color: "#fff", // Cambia el color del tilde a blanco
                    },
                    color: "#fff",
                  }}
                />

                <span style={{ color: "#fff", marginLeft: -4, fontWeight: "bold", fontSize: "12px" }}>RECORDAR</span>
              </label>
            </Box>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  border: 1,
                  borderRadius: 2,
                  fontWeight: "bold",
                  backgroundColor: "#00427f",
                  ":hover": {
                    backgroundColor: "#00427f",
                  },
                  fontSize: 20,
                  marginBottom: -2,
                }}
                onClick={handleLogin}
              >
                INGRESAR
              </Button>
            </div>
          </CardContent>
        </Card>
      )}

      <Dialog
        open={showAlert.open}
        onClose={() => setShowAlert({ open: false, type: null })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showAlert.type === "errorCamposVacios"
              ? "Complete todos los campos"
              : showAlert.type === "errorUser"
              ? "Usuario o contraseña incorrectos"
              : showAlert.type === "errorConexion"
              ? `No se pudo conectar al host ${formattedNic}`
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert({ open: false, type: null })} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </div>
  );
};

export default Login;
