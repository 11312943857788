import React, { useState } from "react";
import { Box, Button, Alert, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import { useCanjeStore, useProductStore } from "../hooks";

const ObtenerDNIPuntos = () => {
  const [showDNIForm, setShowDNIForm] = useState(false);
  const [dni, setDNI] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { setDni } = useCanjeStore(); // Accedemos a los productos desde el store
  const { productos } = useProductStore();
  // Verificar el valor de sugerirRedimirLatamPass en la URL
  const searchParams = new URLSearchParams(location.search);
  const medioDePago = searchParams.get("medioDePago");
  const sugerirRedimirLatamPass = searchParams.get("sugerirRedimirLatamPass") === "true";

  const handleDNIFormSubmit = async () => {
    const isValidDNI = /^\d{8}$/.test(dni);
    if (!isValidDNI) {
      setAlertMessage("Por favor, ingrese un DNI válido.");
      return;
    }

    localStorage.setItem("numeroDocumentoSHELL", dni);
    localStorage.setItem("cargaPuntoShellBox", true);
    setDni(dni); // Guardamos el dni en Redux

    if (sugerirRedimirLatamPass) {
      navigate(`/canjespage?medioDePago=${medioDePago}`);
    } else {
      navigate(`/formasdepagopage?medioDePago=${medioDePago}`);
    }
  };

  const handleNoClick = () => {
    const incluyeMotosport = (productos || []).some(producto => {
      const descripcion = producto.descripcion || producto.descripcionArticulo || "";
      return descripcion.toUpperCase().includes("MOTORSPORT");
    });

    if (incluyeMotosport) {
      setErrorMessage("Por favor, realizar el canje de puntos de la promoción para poder facturar el autito.");
      setTimeout(() => {
        navigate("/home");
      }, 3000);
      return;
    }

    if (sugerirRedimirLatamPass) {
      navigate(`/obtenerdnipuntos?medioDePago=${medioDePago}`);
    } else {
      navigate(`/formasdepagopage?medioDePago=${medioDePago}`);
    }
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: "80%",
          maxWidth: "600px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        {!showDNIForm ? (
          <>
            <Typography sx={{ mb: 4, textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}>
              {sugerirRedimirLatamPass ? "¿Desea canjear puntos de su tarjeta Shell Box?" : "¿Desea sumar puntos ShellBox?"}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => setShowDNIForm(true)}
                variant="contained"
                sx={{
                  backgroundColor: theme.bandera?.colorSecundario,
                  color: "white",
                  fontWeight: "bold",
                  width: "45%",
                  height: "80px",
                  borderRadius: "8px",
                  fontSize: "18px",
                  ":hover": {
                    backgroundColor: theme.bandera?.colorSecundario,
                  },
                }}
              >
                Sí
              </Button>

              <Button
                onClick={handleNoClick}
                variant="outlined"
                sx={{
                  color: theme.bandera?.colorSecundario,
                  fontWeight: "bold",
                  width: "45%",
                  height: "80px",
                  borderRadius: "8px",
                  fontSize: "18px",
                  borderWidth: "2px",
                }}
              >
                No
              </Button>
            </Box>

            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </>
        ) : (
          <>
            <Typography sx={{ mb: 4, textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}>Ingrese el DNI</Typography>

            <TextField
              label="DNI"
              value={dni}
              variant="outlined"
              fullWidth
              type="number"
              sx={{ mb: 4 }}
              onChange={e => setDNI(e.target.value)}
              InputLabelProps={{
                style: { fontSize: "0.85rem", color: "gray" },
              }}
            />

            <Button
              onClick={handleDNIFormSubmit}
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: theme.bandera?.colorSecundario,
                color: "white",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: theme.bandera?.colorSecundario,
                },
              }}
            >
              Aceptar
            </Button>

            {alertMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {alertMessage}
              </Alert>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ObtenerDNIPuntos;
