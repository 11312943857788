import axios from "axios";

const getArticulosHabilitados = async searchString => {
  const formattedNic = localStorage.getItem("formattedNic");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"), idPuesto: puesto.idPuesto, pageNumber: 1, searchString: searchString };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Articulos/GetArticulos`, userData);
  return response;
};

const articulos = {
  getArticulosHabilitados,
};

export default articulos;
