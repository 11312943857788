import axios from "axios";

const postVenta = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const facturaJson = localStorage.getItem("facturaJson");
  const cuponClover = localStorage.getItem("CuponClover");

  const userData = {
    nic: formattedNic,
    token: token,
    venta: facturaJson,
    cuponClover,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/venta`, userData, {
      timeout: 300000,
    });

    console.log(response.data);
    localStorage.setItem("CAE", response.data.CAE);
    localStorage.setItem("prefijo", response.data.Prefijo);
    localStorage.setItem("numero", response.data.Numero);
    return response;
  } catch (error) {
    console.log(error.response.data.message);
    localStorage.setItem("errorMensaje", error.response.data.exceptionMessage);
    localStorage.setItem("reintentos", 1);
  }
};

const venta = {
  postVenta,
};

export default venta;
