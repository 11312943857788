import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articulosClickeados: [],
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    incrementarArticulo: (state, action) => {
      const existingArticulo = state.articulosClickeados.find(articulo => articulo.idArticulo === action.payload.idArticulo);

      if (existingArticulo) {
        existingArticulo.cantidadArticulo += 1;
        existingArticulo.importe = existingArticulo.cantidadArticulo * existingArticulo.precioPublico;
      } else {
        state.articulosClickeados.push({
          ...action.payload,
          cantidadArticulo: 1,
          importe: action.payload.precioPublico,
        });
      }
    },
    decrementarArticulo: (state, action) => {
      const articulo = state.articulosClickeados.find(articulo => articulo.idArticulo === action.payload.idArticulo);

      if (articulo) {
        articulo.cantidadArticulo -= 1;
        if (articulo.cantidadArticulo <= 0) {
          state.articulosClickeados = state.articulosClickeados.filter(art => art.idArticulo !== action.payload.idArticulo);
        }
      }
    },
    actualizarArticulo: (state, action) => {
      state.articulosClickeados = action.payload;
    },
    reiniciarArticulos: state => {
      state.articulosClickeados = [];
    },
  },
});

export const { incrementarArticulo, decrementarArticulo, actualizarArticulo, reiniciarArticulos } = articleSlice.actions;
