import { useDispatch, useSelector } from "react-redux";
import { setCanje, actualizarCanje, reiniciarCanje, setDniCanje, setRedimirRespuesta } from "../store/canje/canjeSlice";

export const useCanjeStore = () => {
  const premio = useSelector(state => state.canje.premio);
  const dniCanje = useSelector(state => state.canje.dniCanje);
  const redimirRespuesta = useSelector(state => state.canje.redimirRespuesta);

  const dispatch = useDispatch();

  const addCanje = canje => {
    dispatch(setCanje(canje));
  };

  const clearCanje = () => {
    dispatch(reiniciarCanje());
  };

  const updateCanje = canje => {
    dispatch(actualizarCanje(canje));
  };

  const setDni = dni => {
    dispatch(setDniCanje(dni));
  };

  const setRedimir = respuesta => {
    dispatch(setRedimirRespuesta(respuesta));
  };

  return {
    // Propiedades
    premio,
    dniCanje,
    redimirRespuesta,

    // Metodos
    addCanje,
    clearCanje,
    updateCanje,
    setDni,
    setRedimir,
  };
};
