import axios from "axios";

const getIdTalonarioQR = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Terminales/GetTerminalQR`, userData);
  return response;
};

const talonarioQR = {
  getIdTalonarioQR,
};

export default talonarioQR;
