import axios from "axios";

const getClientes = async searchTerm => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = {
    nic: formattedNic,
    token: token,
    pageNumber: 1,
    searchString: searchTerm,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Clientes/GetClientes`, userData);
  console.log(response);
  return response;
};

const clientes = {
  getClientes,
};

export default clientes;
