import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ConsultarSaldoShellBox from "../api/consultarSaldoShellBox";
import { useCanjeStore } from "../hooks";

const CanjesPage = () => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery("(min-width:850px)");
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { addCanje, premio, setRedimir, clearCanje } = useCanjeStore();
  const searchParams = new URLSearchParams(location.search);
  const medioDePago = searchParams.get("medioDePago");

  useEffect(() => {
    const fetchCanjes = async () => {
      try {
        const response = await ConsultarSaldoShellBox.acumularOFidelizarVenta();
        const canjes = response.data.map(item => ({
          idFidelizacionPremio: item.idFidelizacionPremio,
          canje: item.canje,
          unidades: item.unidadesNecesarias,
          descuento: item.descuento,
        }));
        setOptions(canjes);
      } catch (error) {
        console.error("Error al cargar los canjes:", error);
      }
    };

    fetchCanjes();
  }, []);

  const handleSelect = index => {
    setSelected(index);
    addCanje(options[index]);
  };

  const handleCanjes = async () => {
    try {
      if (premio) {
        const res = await ConsultarSaldoShellBox.redimirVenta(premio);
        if (res.status === 200) {
          setRedimir(res.data);
          localStorage.setItem("redimirRespuestaCanje", JSON.stringify(res.data));
          navigate(`/formasdepagopage?medioDePago=${medioDePago}`);
          return;
        }
        setErrorMessage("");
      } else {
        setErrorMessage("Por favor, seleccione un canje antes de continuar.");
        setOpenErrorModal(true);
      }
    } catch (error) {
      const responseError = error.response?.data?.exceptionMessage || "Se produjo un error al procesar la solicitud.";
      setErrorMessage(responseError);
      clearCanje();
      setOpenErrorModal(true);
      console.error("Error al redimir:", responseError);
    }
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: isWideScreen ? "60%" : "80%",
          maxWidth: isWideScreen ? "600px" : "350px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 3,
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: isWideScreen ? 1 : 3,
            fontSize: "1.1rem",
            textTransform: "uppercase",
          }}
        >
          Opciones de Fidelización
        </Typography>

        {/* Contenedor con scroll */}
        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            "&::-webkit-scrollbar": {
              width: "4px", // Ancho del scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.bandera.colorSecundario, // Color de la barra
              borderRadius: "8px", // Bordes redondeados
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: theme.palette.primary.dark, // Color al pasar el mouse
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1", // Color del track (fondo)
            },
          }}
        >
          {isWideScreen ? (
            <Box component="table" width="100%" sx={{ borderCollapse: "collapse" }}>
              <Box component="thead">
                <Box component="tr">
                  <Box component="th" sx={{ textAlign: "left", p: 1 }}>
                    Canje
                  </Box>
                  <Box component="th" sx={{ textAlign: "right", p: 1 }}>
                    U. Necesarias
                  </Box>
                  <Box component="th" sx={{ textAlign: "right", p: 1 }}>
                    Descuento
                  </Box>
                  <Box component="th" sx={{ textAlign: "center", p: 1 }}>
                    Elegir
                  </Box>
                </Box>
              </Box>
              <Box component="tbody">
                {options.map((option, index) => (
                  <Box
                    component="tr"
                    key={option.canje}
                    sx={{
                      backgroundColor: selected === index ? "rgba(29, 33, 29, 0.1)" : "transparent",
                      borderRadius: "4px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    <Box component="td" sx={{ p: 1, fontSize: "0.875rem" }}>
                      {option.canje}
                    </Box>
                    <Box component="td" sx={{ p: 1, textAlign: "right", fontSize: "0.875rem" }}>
                      {option.unidades}
                    </Box>
                    <Box component="td" sx={{ p: 1, textAlign: "right", fontSize: "0.875rem" }}>
                      {option.descuento}
                    </Box>
                    <Box component="td" sx={{ p: 1, textAlign: "center" }}>
                      <Checkbox
                        checked={selected === index}
                        onChange={() => handleSelect(index)}
                        icon={<CheckBoxOutlineBlankIcon sx={{ color: theme.bandera.colorSecundario }} />}
                        checkedIcon={<CheckBoxIcon sx={{ color: theme.bandera.colorSecundario }} />}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            options.map((option, index) => (
              <Box
                key={option.canje}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  mb: 2,
                  backgroundColor: selected === index ? "rgba(29, 33, 29, 0.1)" : "transparent",
                  borderRadius: "4px",
                  border: selected === index ? `1px solid ${theme.bandera.colorSecundario}` : "1px solid #e0e0e0",
                }}
              >
                <Box>
                  <Typography variant="body1" sx={{ fontSize: "0.875rem" }}>
                    {option.canje}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.secondary" }}>
                    U. Necesarias: {option.unidades}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.secondary" }}>
                    Descuento: ${option.descuento}
                  </Typography>
                </Box>
                <Checkbox
                  checked={selected === index}
                  onChange={() => handleSelect(index)}
                  icon={<CheckBoxOutlineBlankIcon sx={{ color: theme.bandera.colorSecundario }} />}
                  checkedIcon={<CheckBoxIcon sx={{ color: theme.bandera.colorSecundario }} />}
                />
              </Box>
            ))
          )}
        </Box>

        <Button
          variant="contained"
          onClick={handleCanjes}
          sx={{
            mt: 2,
            width: "100%",
            backgroundColor: theme.bandera.colorSecundario,
            ":hover": { backgroundColor: theme.bandera.colorSecundario },
            fontSize: "0.875rem",
            padding: "8px 0",
          }}
        >
          Aceptar
        </Button>
      </Box>

      {/* Modal de error */}
      <Dialog open={openErrorModal} onClose={handleCloseErrorModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} sx={{ color: theme.bandera.colorSecundario }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CanjesPage;
