import axios from "axios";

const getClienteAfip = async cuit => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, cuit: cuit, serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover") };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Clientes/GetInformacionCUIT`, userData);
  return response.data;
};

const afip = {
  getClienteAfip,
};

export default afip;
