import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Importar useLocation para obtener la URL
import { useTheme } from "@mui/material/styles";
import { handlePagoEvent, showLoadingSpinner } from "../helpers/pagosHelper";
import DebitoIcon from "../images/debito.png";
import EfectivoPagoIcon from "../images/efectivo-icon.png";
import CreditoIcon from "../images/credito.png";
import qr from "../images/qr.png";
import MenuSection from "../components/MenuSection";
import estadoDespacho from "../api/estadoDespacho";
import ConsultarSaldoShellBox from "../api/consultarSaldoShellBox";
import { useCanjeStore } from "../hooks";

const FormasDePagoPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook para obtener los parámetros de la URL
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const bandera = localStorage.getItem("bandera");
  const listadoQR = localStorage.getItem("conListadoQR");
  const { premio, dniCanje } = useCanjeStore();

  const handleEfectivoEvent = async event => {
    const responsePagoEfectivoJava = event.detail.success;
    localStorage.setItem("responsePagoEfectivoJava", responsePagoEfectivoJava);

    if (responsePagoEfectivoJava) {
      setIsLoading(true);

      const despachosSeleccionados = localStorage.getItem("despachosSeleccionados");

      if (despachosSeleccionados) {
        const despachosArray = JSON.parse(despachosSeleccionados); // Asegúrate de que sea un array
        try {
          // Usamos un bucle for...of para esperar cada despacho antes de navegar
          for (const despachoId of despachosArray) {
            await estadoDespacho.actualizarDespacho(despachoId, 1, 1);
          }
          navigate("/contrapage");
        } catch (error) {
          console.error("Error al actualizar despacho:", error);
          // Manejar error si es necesario
        }
      } else {
        navigate("/contrapage");
      }
    }
  };

  useEffect(() => {
    const eventListener = handlePagoEvent(setIsLoading, navigate);

    window.addEventListener("responsePagoConTarjeta", eventListener);
    window.addEventListener("responsePagoEfectivo", handleEfectivoEvent);

    return () => {
      window.removeEventListener("responsePagoConTarjeta", eventListener);
      window.removeEventListener("responsePagoEfectivo", handleEfectivoEvent);
    };
  }, []);

  // Ejecutar la función de pago automáticamente si se ha seleccionado una forma de pago en la URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search); // Obtener parámetros de la URL
    const medioDePago = searchParams.get("medioDePago"); // Extraer "medioDePago" de la URL

    if (medioDePago) {
      switch (medioDePago) {
        case "debito":
          handleDebito();
          break;
        case "credito":
          handleCredito();
          break;
        case "efectivo":
          handleCloverEfectivo();
          break;
        case "qr":
          handleDebito();
          break;
        default:
          break;
      }
    }
  }, [location]); // Ejecutar el efecto cuando cambie la ubicación (URL)

  const handleDebito = () => {
    let valueToSend = parseFloat(localStorage.getItem("totalProductos"));

    if (dniCanje && premio?.descuento) {
      valueToSend -= premio.descuento;
    }

    valueToSend = valueToSend.toString();
    try {
      window.MyInterface.startPayment(valueToSend, null);
    } catch (error) {
      console.error("Falló la llamada con dos parámetros, intentando con uno.");
      window.MyInterface.startPayment(valueToSend);
    }
  };

  const handleCloverEfectivo = () => {
    let valueToSend = parseFloat(localStorage.getItem("totalProductos"));

    if (dniCanje && premio?.descuento) {
      valueToSend -= premio.descuento;
    }

    valueToSend = valueToSend.toString();
    localStorage.removeItem("CuponClover");

    window.MyInterface.startPaymentEfectivo(valueToSend);
  };

  const handleCredito = () => {
    navigate("/cuotaspage");
  };

  const handleQR = () => {
    if (listadoQR == "true") {
      navigate("/listadoqrpage");
    } else if (listadoQR == "false") {
      navigate("/pagoqrpage");
    } else {
      navigate("/listadoqrpage");
    }
  };

  // Función para manejar la elección de pago y verificar si se deben acumular puntos
  const handleEleccionPago = async (medioDePago, metodoPagoCallback) => {
    const bandera = localStorage.getItem("bandera");

    if (bandera === "shell") {
      try {
        // Llamar primero a sugerirRedimirLatamPass
        const response = await ConsultarSaldoShellBox.sugerirRedimirLatamPass();

        if (response.status === 200) {
          // Si la respuesta es exitosa, ir a la página para obtener el DNI
          navigate(`/obtenerdnipuntos?medioDePago=${medioDePago}&sugerirRedimirLatamPass=true`);
        } else {
          throw new Error("No se pudo redimir, intenta acumular puntos");
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          // Si hubo un error 500 en sugerirRedimirLatamPass, intenta acumular puntos
          try {
            await ConsultarSaldoShellBox.acumulaPuntos();
            console.log("Entro a acumular puntos");
            navigate(`/obtenerdnipuntos?medioDePago=${medioDePago}`);
          } catch (acumularError) {
            console.error("Error al intentar acumular puntos:", acumularError);
            metodoPagoCallback(); // Procede sin acumular puntos si ambos fallan
          }
        } else {
          console.error("Error desconocido:", error);
          metodoPagoCallback(); // Procede sin acumular puntos si hay un error diferente
        }
      }
    } else {
      metodoPagoCallback();
    }
  };

  return (
    <>
      <MenuSection />
      {isLoading && showLoadingSpinner()}
      <Box
        sx={{
          position: "absolute",
          top: window.innerWidth < 700 ? "8%" : "12%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h3
          style={{
            backgroundColor: theme.bandera.colorSecundario,
            color: "white",
            fontWeight: "bold",
            padding: "20px 22px",
            width: "100%",
            textAlign: "center",
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          SELECCIONE UNA FORMA DE PAGO:
        </h3>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: window.innerWidth < 700 ? "repeat(2, 1fr)" : "repeat(3, 1fr)",
            gap: "80px",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("debito", handleDebito)} // Primero verificamos si se acumulan puntos
          >
            <img src={DebitoIcon} alt="Debito" style={{ width: "70px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              DÉBITO
            </Typography>
          </Button>

          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("credito", handleCredito)} // Primero verificamos si se acumulan puntos
          >
            <img src={CreditoIcon} alt="Credito" style={{ width: "70px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              CRÉDITO
            </Typography>
          </Button>

          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("qr", handleDebito)} // Primero verificamos si se acumulan puntos
          >
            <img src={qr} alt="QR FISERV" style={{ width: "60px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              QR FISERV
            </Typography>
          </Button>

          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("efectivo", handleCloverEfectivo)} // Primero verificamos si se acumulan puntos
          >
            <img src={EfectivoPagoIcon} alt="Efectivo" style={{ width: "60px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              EFECTIVO
            </Typography>
          </Button>

          {(bandera === "ypf" || bandera === "shell") && (
            <Button
              sx={{
                width: "70px",
                height: "70px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={handleQR}
            >
              <img src={theme.bandera.logoPago} alt="Clover" style={{ width: "60px" }} />
              <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
                {theme.bandera.textoBotonPago}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FormasDePagoPage;
