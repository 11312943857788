import axios from "axios";

const cancelar = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const IdPosicionQR = localStorage.getItem("idPosicionQR");

  const userData = { nic: formattedNic, token: token, IdPosicionQR: IdPosicionQR, serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover") };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/CancelarVentaQR`, {
      userData,
    });
    console.log(response);

    return response;
  } catch (error) {
    console.log(error);
  }
};

const cancelarVentaQR = {
  cancelar,
};

export default cancelarVentaQR;
