import axios from "axios";

const getPercepciones = async clienteStr => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    cliente: clienteStr, // Add clienteStr to userData
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Clientes/ClientePagaPercepciones`, userData);
  return response.data; // Ensure to return the actual data
};

const percepciones = {
  getPercepciones,
};

export default percepciones;
