import axios from "axios";

const cerrarSesion = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  // const serialNumberKeyClover = localStorage.getItem("serialNumberKeyClover");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Vendedores/CierreSesion`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sesion = {
  cerrarSesion,
};

export default sesion;
