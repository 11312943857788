import React, { useState, useRef } from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import "./signCanvas.css";
import Menu from "../components/MenuSection";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import estadoDespacho from "../api/estadoDespacho";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const SignaturePadComponent = () => {
  const [imageURL, setImageURL] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const signCanvas = useRef({});
  const navigate = useNavigate();
  const theme = useTheme();
  const clear = () => signCanvas.current.clear();

  const save = async () => {
    const imageBase64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const imageBytes = base64ToBytes(imageBase64.split(",")[1]); // Convierte base64 a bytes
    console.log(imageBytes);
    localStorage.setItem("firma", imageBase64);
    localStorage.setItem("pagoConCtaCte", "true");

    const despachosArray = JSON.parse(localStorage.getItem("despachosSeleccionados")) || [];
    let allUpdatesSuccessful = true; // Asumimos que todos los updates serán exitosos inicialmente

    for (let idDespacho of despachosArray) {
      try {
        await estadoDespacho.actualizarDespacho(idDespacho, 1, 1, imageBytes);
      } catch (error) {
        console.error("Error al actualizar el despacho:", error);
        apiErrorHandler(error, { stopFlow: true });
      }
    }

    navigate("/contrapage");
  };

  const base64ToBytes = base64 => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  return (
    <div className="App">
      <Menu />
      <Popup open={isOpen} closeOnDocumentClick={false}>
        {close => (
          <>
            <Typography sx={{ fontWeight: "bold", marginTop: 4.5 }} variant="h5">
              Ingrese su firma
            </Typography>
            <SignaturePad
              ref={signCanvas}
              canvasProps={{
                className: "signatureCanvas",
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                  marginRight: theme.spacing(2),
                }}
                onClick={() => clear()}
              >
                BORRAR
              </Button>
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={() => save()}
              >
                ACEPTAR
              </Button>
            </div>
          </>
        )}
      </Popup>
    </div>
  );
};

export default SignaturePadComponent;
