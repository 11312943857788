import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  premio: null,
  dniCanje: null,
  redimirRespuesta: null,
};

export const canjeSlice = createSlice({
  name: "canje",
  initialState,
  reducers: {
    setCanje: (state, action) => {
      state.premio = action.payload;
    },
    actualizarCanje: (state, action) => {
      state.premio = action.payload;
    },
    setDniCanje: (state, action) => {
      state.dniCanje = action.payload;
    },
    reiniciarCanje: state => {
      state.premio = null;
      state.dniCanje = null;
      state.redimirRespuesta = null;
    },
    setRedimirRespuesta: (state, action) => {
      state.redimirRespuesta = action.payload;
    },
  },
});

export const { setCanje, actualizarCanje, setDniCanje, reiniciarCanje, setRedimirRespuesta } = canjeSlice.actions;
