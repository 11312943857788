import MenuSection from "../components/MenuSection";
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import empleados from "../api/empleados";
import cierreAnterior from "../api/cierreAnterior";
import { useNavigate } from "react-router-dom";
import cierreDeTurno from "../api/cierreDeTurno";
import puedeCerrarTurno from "../api/puedeCerrarTurno";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const CierreTurnoPage = () => {
  const [modalWidth, setModalWidth] = useState(window.innerWidth < 700 ? "360px" : "100%");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const puesto = localStorage.getItem("puesto");
  const puestoObj = JSON.parse(puesto);
  const nombre = puestoObj.nombre;
  const [empleadosActivos, setEmpleadosActivos] = useState([]);
  const [cierreAnteriorValue, setCierreAnteriorValue] = useState("");
  const [empleadoInputs, setEmpleadoInputs] = useState([]);
  const idCaja = puestoObj.caja.idCaja;
  const [cierreActual, setCierreActual] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedPlayero, setSelectedPlayero] = useState("");
  const [selectedEncargado, setSelectedEncargado] = useState("");
  const [selectedPlayeros, setSelectedPlayeros] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [dialogo, setDialogo] = useState("");
  const [playeroError, setPlayeroError] = useState(false);
  var cantidadPlayerosSolicitados = localStorage.getItem("cantidadPlayerosSolicitados");
  cantidadPlayerosSolicitados = cantidadPlayerosSolicitados - 1;
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate("/home");
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
  };

  const puedeCerrar = async () => {
    try {
      const puedeCerrarTurnoActual = await puedeCerrarTurno.getPuedeCerrarTurno(idCaja);
      console.log(puedeCerrarTurnoActual);
      setDialogo(puedeCerrarTurnoActual);
      setOpenConfirmDialog(true);
    } catch (error) {
      console.log(error);
      apiErrorHandler(error, { stopFlow: true });
    }
  };

  const handleConfirm = async () => {
    console.log(selectedEncargado);
    setOpenConfirmDialog(false);

    const filledPlayeros = [...selectedPlayeros];
    while (filledPlayeros.length < 8) {
      filledPlayeros.push(null);
    }
    const cierreTurno = {
      IdCaja: idCaja,
      IdPuesto: puestoObj.idPuesto,
      Descripcion: dialogo,
      Encargado: selectedEncargado
        ? {
            IdEncargado: selectedEncargado.idEmpleado,
            Descripcion: selectedEncargado.nombreEmpleado,
          }
        : null,
      Playeros: filledPlayeros.map(playero =>
        playero
          ? {
              IdPlayero: playero.idEmpleado,
              Descripcion: playero.nombreEmpleado,
            }
          : null,
      ),
    };
    setIsLoading(true);

    try {
      const cierreTurnoJSON = JSON.stringify(cierreTurno);
      const response = await cierreDeTurno.postCierreDeTurno(cierreTurnoJSON);
      console.log(localStorage.getItem("errorMensajeCierreTurno"));
      const errorCierreTurno = localStorage.getItem("errorMensajeCierreTurno");
      if (errorCierreTurno) {
        setDialogTitle("No se pudo cerrar el turno");
        setDialogMessage(errorCierreTurno);
      } else {
        setDialogTitle("Cierre de turno");
        setDialogMessage(response);
      }
    } catch (error) {
      apiErrorHandler(error, { stopFlow: true });
    }

    setIsLoading(false);
    setOpenSuccessDialog(true);
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchEmpleadosActivos = async () => {
      try {
        const empleadosAux = await empleados.getEmpleadosActivos();
        setEmpleadosActivos(empleadosAux);
        console.log(empleadosAux);
      } catch (error) {
        console.error(error);
        apiErrorHandler(error, { stopFlow: true });
      }
      setIsLoading(false);
    };

    fetchEmpleadosActivos();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      now.setHours(now.getHours() - 3); // Ajuste manual para UTC-3
      const formattedDateTime = now.toISOString().slice(0, 19); // Incluye segundos en el formato correcto para datetime-local
      setCierreActual(formattedDateTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchCierreAnterior = async () => {
      console.log("fetchCierreAnterior");
      try {
        const cierre = await cierreAnterior.getCierreAnterior(idCaja);
        console.log(cierre);

        // Convertir fecha y hora al formato datetime-local
        const [day, month, year] = cierre.fecha.split("/");
        const datetime = `${year}-${month}-${day}T${cierre.hora}`;
        setCierreAnteriorValue(datetime);
      } catch (error) {
        console.error(error);
        apiErrorHandler(error, { stopFlow: true });
      }
    };

    fetchCierreAnterior();
  }, [idCaja]);

  useEffect(() => {
    console.log(cantidadPlayerosSolicitados);
    const inputs = [];
    for (let index = 0; index < cantidadPlayerosSolicitados; index++) {
      inputs.push("");
    }
    setEmpleadoInputs(inputs);
  }, []);

  const handleAddEmpleado = () => {
    setEmpleadoInputs([...empleadoInputs, ""]);
    console.log(empleadoInputs);
  };

  const textFieldStyles = {
    mb: 1.5,
    "& .MuiInputBase-root": {
      height: "40px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.85rem",
      color: "gray",
    },
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "50%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "90%",
          overflowY: "auto",
        }}
      >
        <Box sx={{ width: window.innerWidth < 700 ? "100%" : "50%", marginLeft: window.innerWidth < 700 ? "0%" : "25%" }}>
          <TextField label="CAJA" variant="outlined" fullWidth value={nombre} sx={textFieldStyles} disabled />
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "8rem",
                height: window.innerWidth < 700 ? 360 : 290,
              }}
            >
              <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
            </div>
          ) : (
            <>
              <Grid container spacing={0.5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Encargado"
                    select
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles}
                    onChange={e => {
                      const playero = empleadosActivos.find(emp => emp.nombreEmpleado === e.target.value);
                      setSelectedEncargado(playero);
                    }}
                  >
                    {empleadosActivos.map(option => (
                      <MenuItem key={option.nombreEmpleado} value={option.nombreEmpleado}>
                        {option.nombreEmpleado}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Playero"
                    select
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles}
                    value={selectedPlayero}
                    onChange={e => {
                      const playero = empleadosActivos.find(emp => emp.nombreEmpleado === e.target.value);
                      setSelectedPlayero(e.target.value);
                      setPlayeroError(false);
                      setSelectedPlayeros([...selectedPlayeros, playero]);
                    }}
                    error={playeroError}
                    helperText={playeroError ? "Por favor, seleccione un Playero" : ""} // Mostrar mensaje de error
                  >
                    {empleadosActivos.map(option => (
                      <MenuItem key={option.nombreEmpleado} value={option.nombreEmpleado}>
                        {option.nombreEmpleado}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {empleadoInputs.map((input, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <TextField
                      label={`Playero ${index + 2}`}
                      select
                      variant="outlined"
                      fullWidth
                      sx={textFieldStyles}
                      value={selectedPlayeros[index + 1] ? selectedPlayeros[index + 1].nombreEmpleado : ""}
                      onChange={e => {
                        const playero = empleadosActivos.find(emp => emp.nombreEmpleado === e.target.value);
                        const newSelectedPlayeros = [...selectedPlayeros];
                        newSelectedPlayeros[index + 1] = playero;
                        setSelectedPlayeros(newSelectedPlayeros);
                      }}
                      error={playeroError}
                      helperText={playeroError ? "Por favor, seleccione un Playero" : ""} // Mostrar mensaje de error
                    >
                      {empleadosActivos.map(option => (
                        <MenuItem key={option.nombreEmpleado} value={option.nombreEmpleado}>
                          {option.nombreEmpleado}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <TextField
                    label="Cierre anterior"
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    value={cierreAnteriorValue}
                    sx={textFieldStyles}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Cierre actual"
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    value={cierreActual}
                    sx={textFieldStyles}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start", // Alinea el contenido a la izquierda
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    fontWeight: "bold",
                    fontSize: 17,
                  }}
                  onClick={handleAddEmpleado}
                >
                  Agregar Empleado
                </Button>
              </Box>
            </>
          )}

          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Alinea el contenido a la derecha
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  marginTop: "10px",
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={() => {
                  if (!selectedPlayero) {
                    setPlayeroError(true);
                    return;
                  }
                  puedeCerrar();
                }}
              >
                CERRAR TURNO
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Cierre de turno"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem" }}>{dialogo}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Cancelar
            </Typography>
          </Button>

          <Button onClick={handleConfirm} color="primary" autoFocus>
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Aceptar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSuccessDialog}
        onClose={handleCloseSuccessDialog}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle id="success-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem" }}>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary" autoFocus>
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Aceptar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CierreTurnoPage;
