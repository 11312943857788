import { useDispatch, useSelector } from "react-redux";
import { incrementarArticulo, decrementarArticulo, actualizarArticulo, reiniciarArticulos } from "../store";

export const useArticleStore = () => {
  const { articulosClickeados } = useSelector(state => state.article);
  const dispatch = useDispatch();

  const addArticulo = articulo => {
    dispatch(incrementarArticulo(articulo));
  };

  const removeArticulo = articulo => {
    dispatch(decrementarArticulo(articulo));
  };

  const updateArticulo = articulos => {
    dispatch(actualizarArticulo(articulos));
  };

  const resetArticulos = () => {
    dispatch(reiniciarArticulos());
  };

  return {
    articulosClickeados,
    addArticulo,
    removeArticulo,
    updateArticulo,
    resetArticulos,
  };
};
