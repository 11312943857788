import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Configuraciones from "../pages/ConfiguracionesPage";
import Footer from "./Footer";
import SettingsIcon from "@mui/icons-material/Settings";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import sesion from "../api/cerrarSesion";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const options = [
  { label: "Despacho Manual", icon: <LocalGasStationIcon /> },
  { label: "Consultar Puntos", icon: <LoyaltyIcon />, onlyShell: true },
  { label: "Configuraciones", icon: <SettingsIcon /> },
  { label: "Cerrar Sesión", icon: <LogoutIcon /> },
];

const ITEM_HEIGHT = 70;

const MenuSection = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const theme = useTheme();
  const nic = localStorage.getItem("formattedNic");
  const bandera = localStorage.getItem("bandera");

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = async option => {
    if (option === "Cerrar Sesión") {
      localStorage.removeItem("articulosArray");
      localStorage.removeItem("clientesArray");
      localStorage.removeItem("responsePagoEfectivoJava");
      localStorage.removeItem("CuponClover");
      localStorage.removeItem("pagoConCtaCte");
      localStorage.removeItem("puestosArray");
      localStorage.removeItem("cliente");
      localStorage.removeItem("passEmpleados");
      if (nic == "http://pabloi.caldenoil.com:9009" || nic == "http://cepia.caldenoil.com:5000" || nic == "http://laposta.caldenoil.com:5000") {
        try {
          console.log("entro aca?");
          const response = await sesion.cerrarSesion();
          alert(response);
        } catch (error) {
          console.log(error);
        }
      }
      localStorage.removeItem("serialNumberKeyClover");
      localStorage.removeItem("token");
      navigate("/");
    } else if (option === "Despacho Manual") {
      navigate("/despachomanualpage");
    } else if (option === "Configuraciones") {
      navigate("/configuracionespage");
    } else if (option === "Consultar Puntos") {
      navigate("/consultarpuntos");
    }
  };

  const handleBack = () => {
    const currentPath = window.location.pathname;

    localStorage.setItem("cliente", "");
    console.log(currentPath);

    const restrictedPaths = ["/ticket", "/facturaA", "/facturaB", "/remito", "/contrapage", "/dialogoimprimirfacturapage", "/pagoqrpage"];

    if (!restrictedPaths.includes(currentPath)) {
      navigate("/home");
    }
  };

  // Filtrar opciones según el usuario y si bandera es "shell"
  const filteredOptions = options.filter(option => {
    const optionLabelLower = option.label.toLowerCase();
    const userLower = user.toLowerCase();
    const solicitarAforadores = localStorage.getItem("solicitarAforadores");

    // Filtra las opciones basadas en el usuario y bandera
    if (option.onlyShell && bandera !== "shell") {
      return false; // Si es una opción que solo es para shell y la bandera no es shell, no mostrar
    }

    if (userLower === "clover" || userLower === "admin") {
      return true; // Los usuarios "clover" y "admin" pueden ver todas las opciones
    } else if (solicitarAforadores === "false") {
      return optionLabelLower !== "despacho manual"; // Excluye "Despacho Manual" para otros usuarios
    } else {
      return optionLabelLower !== "configuraciones"; // Excluye "Configuraciones" para otros usuarios
    }
  });

  return (
    <>
      <div style={{ position: "absolute", top: 0, left: 0 }}>
        <AppBar style={{ backgroundColor: theme.bandera.colorHeader }}>
          <Toolbar>
            <Typography component="div" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <img
                onClick={handleBack}
                src={theme.bandera.image}
                alt="Logo Calden Oil"
                style={{ width: theme.bandera.anchoLogo, height: "auto", cursor: "pointer" }}
              />
            </Typography>

            {nic === "http://pabloi.caldenoil.com:9009" && (
              <Typography component="div" sx={{ flexGrow: 1, display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "30px" }}>
                DEV{" "}
              </Typography>
            )}
            <IconButton
              edge="end"
              sx={{ color: theme.bandera.colorIconoHeader }}
              aria-label="menu"
              id="long-button"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5.5,
                  width: window.innerWidth > 700 ? "25%" : "65%",
                },
              }}
            >
              {filteredOptions.map(option => (
                <MenuItem
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "1px solid #ccc",
                    marginBottom: "4px",
                    display: "flex",
                    alignItems: "center",
                    height: ITEM_HEIGHT + 10,
                    gap: 1,
                  }}
                  key={option.label}
                  onClick={() => handleOptionClick(option.label)}
                >
                  {option.icon}
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
      <Footer />
    </>
  );
};

export default MenuSection;
