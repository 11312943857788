import axios from "axios";

const getCategoriasIVA = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover") };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/CategoriasIVA/GetCategoriasIVA`, userData);
  return response.data;
};

const categoriasIVA = {
  getCategoriasIVA,
};

export default categoriasIVA;
