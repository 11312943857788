import axios from "axios";

const getTransferencias = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const idCaja = localStorage.getItem("idCaja");

  const userData = {
    nic: formattedNic,
    token: token,
    idCaja: idCaja,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/GetTransferencias`, userData);
  return response.data;
};

const realizarTransferencia = async (IdEmpleado, Efectivo, TipoTransferencia) => {
  const nic = localStorage.getItem("formattedNic");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const idTalonarioB = localStorage.getItem("idTalonarioB");
  const token = localStorage.getItem("token");
  const datosTransferencia = {
    idCaja: puesto.caja.idCaja,
    idPuesto: puesto.idPuesto,
    idTalonario: idTalonarioB,
    LetraComprobante: "B",
    TipoComprobante: "Factura",
    IdEmpleado: IdEmpleado,
    IdCierreTurno: null,
    ImputarACajaCerrada: false,
    Efectivo: Efectivo,
    TipoTransferencia: TipoTransferencia,
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/RealizarTransferencia`, {
    datosTransferencia,
    nic,
    token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  });
  return response;
};

const transferencias = {
  getTransferencias,
  realizarTransferencia,
};

export default transferencias;
