import axios from "axios";

const sendEmail = async () => {
  try {
    await getComprobante();
  } catch (error) {
    console.error("Error al enviar el email", error);
  }
};

const getComprobante = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const idMovimientoFac = JSON.parse(localStorage.getItem("idMovimientoFac"));
  const clienteString = localStorage.getItem("cliente");
  const facturaFinal = JSON.parse(localStorage.getItem("facturaFinal"));

  let cliente;
  try {
    // Intenta parsear el clienteString sólo si no es null
    cliente = clienteString ? JSON.parse(clienteString) : null;
  } catch (e) {
    console.error("Error parsing client data:", e);
    cliente = null;
  }

  const email = cliente && cliente.email ? cliente.email : "pablogonzalez@grupoaoniken.com.ar";
  const nroCae = facturaFinal && facturaFinal.CAENumero ? facturaFinal.CAENumero : "";

  for (const movimiento of idMovimientoFac) {
    const userData = {
      nic: formattedNic,
      token: token,
      mail: email,
      nroComprobante: nroCae,
      idMovimientoFac: movimiento,
      serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
    };

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/GetMovimientoFac`, userData, {
      responseType: "blob",
    });
  }
};

export default { sendEmail };
