// apiErrorHandler.js
import React from "react";
import ReactDOM from "react-dom";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { FiAlertTriangle } from "react-icons/fi";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography, Box } from "@mui/material";

// Función para renderizar una alerta estilizada
const renderAlert = (message, severity = "error", onCloseCallback = null) => {
  const div = document.createElement("div");
  document.body.appendChild(div);

  const closeAlert = () => {
    ReactDOM.unmountComponentAtNode(div);
    div.remove();
    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  // Separar la parte en negrita de la parte normal
  const [boldPart, ...restParts] = message.split("\n\n");
  const normalMessage = restParts.join("\n\n");

  ReactDOM.render(
    <Alert
      severity={severity}
      icon={
        (severity === "error" || severity === "warning") && (
          <Box
            sx={{
              fontSize: "4rem",
              lineHeight: "6rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertTriangle />
          </Box>
        )
      }
      sx={{
        position: "fixed",
        top: "20%",
        left: "50%",
        transform: "translateX(-50%)",
        width: "95%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1300,
        "& .MuiAlert-message": {
          fontSize: "1.5rem",
          textAlign: "center",
        },
      }}
      action={
        <>
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={closeAlert}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              fontSize: "25px",
            }}
          >
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
          <Button
            onClick={closeAlert}
            color="primary"
            variant="contained"
            sx={{
              marginTop: 2,
              alignSelf: "flex-end",
              marginRight: 2,
            }}
          >
            Aceptar
          </Button>
        </>
      }
    >
      <Typography component="div" sx={{ fontSize: "1.05rem" }}>
        <strong>{boldPart}</strong>
        <br />
        <br />
        {normalMessage.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Alert>,
    div,
  );
};

const apiErrorHandler = (error, options = {}) => {
  const { stopFlow, customHandlers, rethrow } = options;

  if (error.response) {
    const { status, data, config } = error.response;

    // Convertir `data` en un string legible si es un objeto
    const errorMessage = typeof data === "object" ? JSON.stringify(data, null, 2) : data;

    // Obtener la última parte del endpoint
    const endpoint = config?.url?.split("/").pop() || "Endpoint desconocido";

    // Mensajes personalizados según el código de estado HTTP
    const messageMap = {
      401: `No autorizado. Por favor, inicie sesión.\n\nEndpoint: ${endpoint}`,
      409: `${errorMessage}\n\nEndpoint: ${endpoint}`,
      500: `Error interno del servidor: ${errorMessage}\n\nEndpoint: ${endpoint}`,
      default: `Error inesperado: ${status}\n\nEndpoint: ${endpoint}`,
    };
    const message = messageMap[status] || messageMap.default;

    // Verificar si existe un handler personalizado para este estado
    if (customHandlers?.[status]) {
      customHandlers[status](data);
    } else {
      // Determinar la severidad del mensaje
      const severity = [401, 500].includes(status) ? "error" : "warning";

      if (stopFlow) {
        // Redirigir según el código de estado
        const redirectUrl = status === 401 ? "/login" : "/";
        renderAlert(message, severity, () => {
          if ([401, 409].includes(status)) {
            window.location.replace(redirectUrl);
          }
        });
      } else {
        renderAlert(message, severity);
      }
    }
  } else {
    console.error("Error de red o desconocido:", error.message);
    renderAlert("Error de conexión. Verifique su conexión a Internet.", "error");
  }

  if (rethrow) {
    throw error;
  }
};

export default apiErrorHandler;
