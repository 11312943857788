import axios from "axios";

const getLimiteAfip = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/ObtenerLimiteFacturaBSinDNI`, userData);
  return response.data;
};

const limiteAfip = {
  getLimiteAfip,
};

export default limiteAfip;
