import axios from "axios";

const formatearNIC = NIC => {
  NIC = NIC.toLowerCase(NIC);
  if (isValidURL(NIC) || isSubdomain(NIC)) {
    return NIC;
  } else if (isAlphanumeric(NIC)) {
    return "http://" + NIC + ".caldenoil.com";
  } else {
    if (NIC.match(/:/g).length === 1) {
      var tokens = NIC.split(":");
      return "http://" + tokens[0] + ".caldenoil.com:" + tokens[1];
    } else {
      return "http://" + NIC + ".caldenoil.com";
    }
  }
};
const validarUsuario = async userData => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Vendedores/IsVendedorValido`,
      // `${process.env.REACT_APP_API_URL}/api/Vendedores/IsVendedorValido`,
      userData, // Envía las credenciales en el cuerpo de la solicitud
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const isValidURL = s => {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(s);
};

const isSubdomain = url => {
  var regex = new RegExp(/^([a-z]+:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  return !!url.match(regex);
};

const isAlphanumeric = str => {
  var regex = new RegExp(/^[a-z0-9]+$/i);
  return !!str.match(regex);
};

const conexion = {
  formatearNIC,
  validarUsuario,
};

export default conexion;
