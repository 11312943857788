import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productos: [],
  idProductoArray: 1,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    agregarProducto: (state, action) => {
      state.productos.push(action.payload);
      state.idProductoArray += 1;
    },
    borrarProductos: state => {
      state.productos = [];
      state.idProductoArray = 1;
    },
    actualizarProducto: (state, action) => {
      state.productos = action.payload;
    },
    actualizarIdProductoArray: (state, action) => {
      state.idProductoArray = action.payload;
    },
    reiniciarProductos: state => {
      state.productos = [];
    },
  },
});

export const { agregarProducto, borrarProductos, actualizarProducto, actualizarIdProductoArray, reiniciarProductos } = productSlice.actions;
