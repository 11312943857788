import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { validateCUITCUIL, handleCuitChange } from "../helpers/useCuit";
import { generarFacturaInfo } from "../helpers/generarFacturaInfo"; // Importa la función
import { FiAlertCircle } from "react-icons/fi"; // Importa el ícono
import { useProductStore } from "../hooks";

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { productos, borrarProductos } = useProductStore();
  const [condicion, setCondicion] = useState("CONTADO");
  const [showModal, setShowModal] = useState(false);
  const [cuit, setCuit] = useState("");
  const [cuitError, setCuitError] = useState(false);

  // Obtén el cliente del localStorage
  const [cliente, setCliente] = useState(null);

  useEffect(() => {
    const cliente = localStorage.getItem("cliente") ? JSON.parse(localStorage.getItem("cliente")) : null;
    const condicionVenta = cliente?.condicionVenta?.condicionVentaContado ?? null;
    const condicionVentaStorage = localStorage.getItem("condicionVenta");

    if (condicionVenta == true) {
      setCondicion(condicionVenta);
      console.log(condicionVenta);
      localStorage.removeItem("condicionVenta");
    } else if (condicionVentaStorage) {
      setCondicion(condicionVenta);
      localStorage.removeItem("condicionVenta");
    } else if (cliente) {
      setCliente(cliente);
      localStorage.removeItem("condicionVenta");
      setCondicion(condicionVenta ? "CONTADO" : "CTA. CTE.");
    }
  }, []);

  const limiteAfip = parseFloat(localStorage.getItem("limite"));

  const buttonStyle = {
    backgroundColor: theme.bandera.colorPrimario,
    color: "white",
    padding: "10px",
    marginTop: "5px",
    marginRight: "40px",
    "&:hover": {
      backgroundColor: theme.bandera.colorSecundario,
    },
  };

  const iconStyle = {
    fontSize: window.innerWidth > 1000 ? "77px" : "55px",
  };

  const handlePagoClick = () => {
    console.log(condicion);
    const facturaInfo = generarFacturaInfo(productos, cliente);
    localStorage.setItem("facturaInfo", JSON.stringify(facturaInfo));
    if (limiteAfip < parseFloat(facturaInfo.total).toFixed(2) && !localStorage.getItem("cliente")) {
      setShowModal(true);
    } else {
      navigate(condicion === "CTA. CTE." ? "/firma" : "/formasdepagopage");
    }
  };

  const handleModalAccept = () => {
    if (validateCUITCUIL(cuit.replace(/-/g, ""))) {
      setShowModal(false);
      localStorage.setItem("cuit", cuit);
      navigate("/formasdepagopage");
    } else {
      setCuitError(true);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleBorrarTodo = () => {
    borrarProductos();
    localStorage.removeItem("cliente");
    localStorage.removeItem("despachosSeleccionados");
    localStorage.removeItem("facturaInfo");
    localStorage.removeItem("totalProductos");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        height: window.innerWidth > 1000 ? "80vh" : "100vh",
        width: "70px",
        position: "fixed",
        right: window.innerWidth > 1000 ? 10 : 0,
      }}
    >
      {/* CLIENTES F4 */}
      <Button variant="contained" sx={buttonStyle} onClick={() => navigate("/clientessearchpage")}>
        <PersonOutlineOutlinedIcon sx={iconStyle} />
      </Button>
      {/* ARTICULOS */}
      <Button variant="contained" sx={buttonStyle} onClick={() => navigate("/articulossearchpage")}>
        <StickyNote2OutlinedIcon sx={iconStyle} />
      </Button>
      {/* PAGAR F2 */}
      <Button
        variant="contained"
        sx={buttonStyle}
        onClick={handlePagoClick}
        disabled={productos.length === 0} // Deshabilitar botón si no hay artículos cargados
      >
        <CreditCardOutlinedIcon sx={iconStyle} />
      </Button>
      {/* TRANSFERENCIAS F3 NO ESTA IMPLEMENTADO */}
      <Button variant="contained" sx={buttonStyle} onClick={() => navigate("/transferenciapage")} disabled>
        <AccountBalanceWalletIcon sx={iconStyle} />
      </Button>
      {/* BORRAR TODO F11 */}
      <Button variant="contained" sx={buttonStyle} onClick={handleBorrarTodo}>
        <DeleteOutlinedIcon sx={iconStyle} />
      </Button>
      {/* CIERRE DE TURNO F6*/}
      <Button variant="contained" sx={buttonStyle} onClick={() => navigate("/CierreTurnoPage")}>
        <NoEncryptionGmailerrorredIcon sx={iconStyle} />
      </Button>

      <Modal open={showModal} onClose={handleModalCancel}>
        <Box
          sx={{
            "& .MuiDialog-paper": {
              padding: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[5],
              borderRadius: theme.shape.borderRadius,
              display: "flex",
              flexDirection: "column",
            },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: theme.spacing(2),
            }}
          >
            <FiAlertCircle size={48} color={theme.palette.info.main} />
          </Box>
          <Typography sx={{ mt: 2, fontSize: 18, fontWeight: 100 }}>Se debe identificar al consumidor final para este importe.</Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 100 }}>Importe máximo: ${limiteAfip}</Typography>
          <Typography sx={{ mt: 2, fontSize: 18, fontWeight: 100 }}>Ingrese un CUIT válido:</Typography>
          <TextField
            label="CUIT"
            value={cuit}
            variant="outlined"
            fullWidth
            sx={{ mb: 4, mt: 2 }}
            onChange={event => handleCuitChange(event, setCuit, setCuitError)}
            error={cuitError}
            helperText={cuitError ? "CUIT debe tener 11 dígitos y ser válido" : ""}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleModalCancel} color="primary" variant="contained" sx={{ mr: 1 }}>
              <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 500 }}>
                Cancelar
              </Typography>
            </Button>
            <Button onClick={handleModalAccept} color="primary" variant="contained">
              <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 500 }}>
                Aceptar
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Sidebar;
