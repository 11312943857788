import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useProductStore } from "../hooks/useProductStore";
import MenuSection from "../components/MenuSection";
import { FiAlertTriangle } from "react-icons/fi";
import { FiAlertCircle } from "react-icons/fi";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LiaUserCheckSolid } from "react-icons/lia";
import { useTheme } from "@mui/material/styles";
import combustibles from "../api/combustibles";
import { verificarEmpleado } from "../helpers/verificarEmpleado";
import manguerasApi from "../api/getManguerasByCombustible";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const DespachoManualPage = () => {
  const [combustible, setCombustible] = useState("");
  const [mangueras, setMangueras] = useState([]);
  const [mangueraSeleccionada, setMangueraSeleccionada] = useState("");
  const [precio, setPrecio] = useState(0);
  const [importe, setImporte] = useState("");
  const [litros, setLitros] = useState(0);
  const [idArticulo, setIdArticulo] = useState(false);
  const [articulosArray, setArticulosArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
  const { addProducto } = useProductStore();
  const [contentVisible, setContentVisible] = useState(true);
  const theme = useTheme();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const storedValue = localStorage.getItem("combustiblesSoloPorDespachos");
  // Verificamos si el valor es `null` o `undefined`, o si es una cadena vacía o "undefined"
  const combustiblesSoloPorDespachos = storedValue && storedValue !== "undefined" && storedValue !== "" ? JSON.parse(storedValue) : false;
  console.log(combustiblesSoloPorDespachos);

  // Función para validar si los campos están completos
  const isFormValid = () => {
    return combustible !== "" && importe > 0 && litros > 0 && mangueraSeleccionada;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await combustibles.getCombustibles();
        setArticulosArray(response);
        console.log(response);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        apiErrorHandler(error, { stopFlow: true });
      }
    };
    fetchData();
  }, []);

  const handleCombustibleChange = async event => {
    const idCombustibleSeleccionado = event.target.value;

    setCombustible(idCombustibleSeleccionado);

    const articuloSeleccionado = articulosArray.find(articulo => articulo.idArticulo === idCombustibleSeleccionado);

    if (articuloSeleccionado) {
      setPrecio(articuloSeleccionado.precioPublico);
      setIdArticulo(articuloSeleccionado.idArticulo);
    }

    const manguerasObtenidas = await manguerasApi.combustible(idCombustibleSeleccionado);
    console.log(manguerasObtenidas);
    setMangueras(manguerasObtenidas);
    setImporte("");
    setLitros(0);
  };

  const handleImporteChange = event => {
    const enteredImporte = parseFloat(event.target.value);
    setImporte(enteredImporte);

    if (precio > 0 && enteredImporte > 0) {
      const calculatedLitros = enteredImporte / precio;
      setLitros(calculatedLitros);
    } else {
      setLitros(0);
    }
  };

  const handleAceptarClick = () => {
    // if (combustiblesSoloPorDespachos) {
    //   setIsValid(true);
    //   setTimeout(() => {
    //     const articuloData = {
    //       descripcionArticulo: combustible,
    //       precioPublico: precio,
    //       importe: parseFloat(importe),
    //       cantidad: litros,
    //       idArticulo: idArticulo,
    //     };
    //     console.log(articuloData);
    //     dispatch({ type: "AGREGAR_PRODUCTO", payload: articuloData });
    //     navigate("/dashboard");
    //     setContentVisible(true);
    //   }, 1500);
    // } else {
    setOpenModal(true);
    // }
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setContentVisible(true);
  };

  const handleAlertOpen = (message, severity) => {
    setAlertMessage(message);
    setAlertOpen(true);
    setContentVisible(false);
    setOpenModal(false); // Cierra el modal cuando se muestra el alert
  };

  const handleModalAccept = async () => {
    setIsButtonDisabled(true); // Deshabilita el botón una vez que se hace clic
    const resultado = await verificarEmpleado(password); // Llamada al helper

    if (resultado.success) {
      const empleado = resultado.empleado;
      localStorage.setItem("idEmpleadoVendedor", empleado.idEmpleado);
      if (empleado.autorizaDespachoManual) {
        handleAlertOpen(
          <span>
            Empleado: <strong>{empleado.nombreEmpleado}</strong> autorizado para hacer despacho manual
          </span>,
        );
        setIsValid(true);
        setTimeout(() => {
          const articuloSeleccionado = articulosArray.find(articulo => articulo.idArticulo === idArticulo);
          const articuloData = {
            descripcionArticulo: articuloSeleccionado ? articuloSeleccionado.descripcion : "Descripción no disponible",
            precioPublico: precio,
            importe: parseFloat(importe),
            cantidad: litros,
            idArticulo: idArticulo,
          };
          localStorage.setItem("despachoManual", true);
          localStorage.setItem("idMangueraSeleccionada", mangueraSeleccionada);
          addProducto(articuloData);
          navigate("/dashboard");
          setContentVisible(true);
        }, 1500);
      } else {
        handleAlertOpen(
          <span>
            Empleado: <strong>{empleado.nombreEmpleado}</strong> no está autorizado para hacer despacho manual
          </span>,
        );
        setIsValid(false);
        setTimeout(() => {
          setAlertOpen(false);
          setContentVisible(true);
          setIsButtonDisabled(false); // Vuelve a habilitar el botón si es necesario
        }, 1500);
      }
    } else {
      handleAlertOpen(resultado.message);
      setIsValid(false);
      setTimeout(() => {
        setAlertOpen(false);
        setContentVisible(true);
        setIsButtonDisabled(false); // Vuelve a habilitar el botón en caso de error
      }, 1500);
    }
  };

  const handleMangueraChange = event => {
    setMangueraSeleccionada(event.target.value); // Actualiza la manguera seleccionada
  };

  return (
    <>
      <MenuSection />
      {contentVisible && (
        <Container>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 1,
              padding: 2,
              marginBottom: 2,
            }}
          >
            <Typography sx={{ fontSize: 20 }} variant="subtitle1" gutterBottom>
              Datos del despacho
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Combustible</InputLabel>
              <Select value={combustible} onChange={handleCombustibleChange} label="Combustible">
                <MenuItem value="">
                  <em>Seleccione un combustible</em>
                </MenuItem>
                {articulosArray.map(despacho => (
                  <MenuItem key={despacho.idArticulo} value={despacho.idArticulo}>
                    {despacho.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Manguera</InputLabel>
              <Select value={mangueraSeleccionada} onChange={handleMangueraChange} label="Manguera">
                <MenuItem value="">
                  <em>Seleccione una manguera</em>
                </MenuItem>
                {mangueras.map(manguera => (
                  <MenuItem key={manguera.idManguera} value={manguera.idManguera}>
                    {manguera.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField fullWidth margin="normal" label="Precio por litro" type="number" value={precio} disabled />
            <TextField fullWidth margin="normal" label="Importe del surtidor" type="number" value={importe} onChange={handleImporteChange} />
            <TextField
              fullWidth
              margin="normal"
              label="Litros"
              type="number"
              value={litros.toFixed(2)}
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={handleAceptarClick}
                disabled={!isFormValid()} // Botón deshabilitado si el formulario no es válido
              >
                <Typography variant="button" sx={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                  ACEPTAR{" "}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Container>
      )}

      {/* Modal para ingresar contraseña */}
      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogTitle>
          <Typography variant="h5" component="div" fontWeight="bold">
            Contraseña
          </Typography>
        </DialogTitle>{" "}
        <DialogContent>
          <TextField fullWidth margin="normal" label="Ingrese su contraseña" type="password" value={password} onChange={handlePasswordChange} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleModalAccept}
            disabled={isButtonDisabled} // Deshabilitar el botón si isButtonDisabled es true
          >
            <Typography variant="button" sx={{ fontWeight: "bold", fontSize: "0.9rem" }}>
              ACEPTAR{" "}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {alertOpen && (
        <Alert
          severity={error ? "error" : isValid ? "success" : "warning"}
          icon={
            error ? (
              <FiAlertTriangle style={{ fontSize: "4rem" }} />
            ) : isValid ? (
              <LiaUserCheckSolid style={{ fontSize: "4rem" }} />
            ) : (
              <FiAlertCircle style={{ fontSize: "4rem" }} />
            )
          }
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiAlert-message": {
              fontSize: "1.25rem",
              textAlign: "center",
            },
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
            maxWidth: 400,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleAlertClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: "25px",
              }}
            >
              <HighlightOffIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      )}
    </>
  );
};

export default DespachoManualPage;
