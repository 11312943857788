export const validaCantidadAutos = (productos, limite = 4) => {
  const totalMotosport = productos
    .filter(producto => {
      const descripcion = producto.descripcion || producto.descripcionArticulo || "";
      return descripcion.toUpperCase().includes("MOTORSPORT");
    })
    .reduce((acc, producto) => acc + producto.cantidadArticulo, 0);

  return totalMotosport > limite;
};
