import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@mui/material";
import MenuSection from "./MenuSection";
import { useTheme } from "@mui/material/styles";
import {
  inicializarCanvas,
  agregarEncabezado,
  agregarDatosCliente,
  agregarImpuestos,
  agregarEmpleadoResponsable,
  agregarCAEyFechaDeVencimiento,
  agregarCodigoQR,
  agregarTitulo,
  agregarNombreChofer,
  agregarDetallesMovimiento,
} from "../helpers/facturaUtils";
import actualizarFirma from "../api/actualizarFirma";

function Comprobante() {
  const navigate = useNavigate();
  const [printing, setPrinting] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    generarComprobantes();
  }, []);

  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    // Función para manejar el evento cuando se presiona el botón de retroceso
    // Aquí puedes redirigir a otra página o simplemente hacer nada
    navigate("/comprobante"); // Redirecciona a otra página según sea necesario
  };

  const obtenerDatosComprobante = () => {
    const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
    const clienteString = localStorage.getItem("cliente");
    const cliente = clienteString ? JSON.parse(clienteString) : null;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("es-AR");
    const formattedTime = currentDate.toLocaleTimeString("es-AR");
    const canvasHeight = +localStorage.getItem("canvasHeight");
    const estacionData = JSON.parse(localStorage.getItem("estacionData"));
    const facturaFinal = JSON.parse(localStorage.getItem("facturaFinal"));
    const percibePercepciones = localStorage.getItem("percibePercepciones");

    const cantidadCopias = {
      FAA: localStorage.getItem("cantidadCopiasFAA"),
      REM: localStorage.getItem("cantidadCopiasREM"),
      FAB: localStorage.getItem("cantidadCopiasFAB"),
    };

    return {
      facturaInfo,
      cliente,
      formattedDate,
      formattedTime,
      canvasHeight,
      estacionData,
      facturaFinal,
      percibePercepciones,
      cantidadCopias,
    };
  };

  const generarComprobantes = async () => {
    const { facturaFinal, cantidadCopias } = obtenerDatosComprobante();

    for (let i = 0; i < facturaFinal.respuestas.length; i++) {
      const { idTipoMovimiento } = facturaFinal.respuestas[i];

      if (idTipoMovimiento === "REM") {
        await generarRemito(facturaFinal.respuestas[i], cantidadCopias.REM);
      } else if (idTipoMovimiento === "FAA") {
        await generarFacturaA(facturaFinal.respuestas[i], cantidadCopias.FAA);
      } else {
        await generarFacturaB(facturaFinal.respuestas[i], cantidadCopias.FAB);
      }
    }

    setPrinting(true);

    setTimeout(() => {
      navigate("/finalizarPago");
    }, 2000);
  };

  const generarRemito = async (respuesta, cantidadCopias) => {
    const { cliente, formattedDate, formattedTime, canvasHeight, estacionData } = obtenerDatosComprobante();
    const context = inicializarCanvas(550, canvasHeight + 100);

    let y = agregarEncabezado(context, estacionData.data, formattedDate, formattedTime, 30);
    y = agregarTitulo(context, "REMITO", "", respuesta.prefijo, respuesta.numero, y);
    y = agregarDatosCliente(context, cliente, y);
    y = agregarNombreChofer(context, y + 20);
    y = agregarDetallesMovimiento(context, respuesta.detallesMovimiento, y + 20);

    await agregarFirma(context, y);
    await actualizarFirma.postActualizarFirma(respuesta.idMovimientoFac);

    imprimir(context, cantidadCopias);
  };

  const generarFacturaA = async (respuesta, cantidadCopias) => {
    const { cliente, formattedDate, formattedTime, canvasHeight, estacionData, percibePercepciones } = obtenerDatosComprobante();
    const context = inicializarCanvas(550, canvasHeight + 100);

    let y = agregarEncabezado(context, estacionData.data, formattedDate, formattedTime, 30);
    y = agregarTitulo(context, "FACTURA", "COD.01", respuesta.prefijo, respuesta.numero, y);
    y = agregarDatosCliente(context, cliente, y);
    y = agregarNombreChofer(context, y + 20);
    y = agregarDetallesMovimiento(context, respuesta.detallesMovimiento, y + 20);

    y = agregarImpuestos(context, respuesta, respuesta.detallesMovimiento.total, "A", percibePercepciones, y + 20);
    y = agregarEmpleadoResponsable(context, y);
    y = agregarCAEyFechaDeVencimiento(context, respuesta, y);
    await agregarCodigoQR(context, respuesta, formattedDate, respuesta.detallesMovimiento.total, cliente, estacionData, y);

    imprimir(context, cantidadCopias);
  };

  const generarFacturaB = async (respuesta, cantidadCopias) => {
    const { cliente, formattedDate, formattedTime, canvasHeight, estacionData } = obtenerDatosComprobante();
    const context = inicializarCanvas(550, canvasHeight + 100);

    let y = agregarEncabezado(context, estacionData.data, formattedDate, formattedTime, 30);
    y = agregarTitulo(context, "FACTURA", "COD.06", respuesta.prefijo, respuesta.numero, y);
    y = agregarDatosCliente(context, cliente, y);
    y = agregarNombreChofer(context, y + 20);
    y = agregarDetallesMovimiento(context, respuesta.detallesMovimiento, y + 20);

    y = agregarImpuestos(context, respuesta, respuesta.detallesMovimiento.total, "B", null, y + 20);
    y = agregarEmpleadoResponsable(context, y);
    y = agregarCAEyFechaDeVencimiento(context, respuesta, y);
    await agregarCodigoQR(context, respuesta, formattedDate, respuesta.detallesMovimiento.total, cliente, estacionData, y);

    imprimir(context, cantidadCopias);
  };

  const agregarFirma = async (context, y) => {
    const image = new Image();
    image.src = localStorage.getItem("firma");

    return new Promise(resolve => {
      image.onload = () => {
        const xOffset = 160;
        context.drawImage(image, xOffset, y);
        resolve();
      };
    });
  };

  const imprimir = (context, cantidadCopias) => {
    const dataURL = context.canvas.toDataURL("image/png");
    const base64Data = dataURL.replace(/^data:image\/png;base64,/, "");
    console.log(base64Data);
    for (let i = 0; i < cantidadCopias; i++) {
      if (!(window.location.hostname === "localhost" && window.location.port === "3000")) {
        window.MyInterface.imprimirFacturaAfip(base64Data);
      }
    }
  };

  return (
    <div>
      <MenuSection />
      <Box className="printing-container" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress sx={{ color: theme.bandera.colorSecundario }} size={50} />
        <Typography variant="body1" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Imprimiendo comprobante...
        </Typography>
      </Box>
    </div>
  );
}

export default Comprobante;
