import React, { useState, useEffect } from "react";
import { Modal, Box, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Despachos from "../api/despachos";
import { useProductStore } from "../hooks/useProductStore";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import estadoDespacho from "../api/estadoDespacho";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation"; // Importar el icono
import apiErrorHandler from "../helpers/apiErrorHandler";
import { argbToCssColor } from "../helpers/colorUtils";

const DespachosSearchPage = () => {
  const [modalWidth, setModalWidth] = useState("500px");
  const [despachoArray, setDespachoArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const { idProductoArray, addProducto } = useProductStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const cantidadAMostrar = localStorage.getItem("cantidadAMostrar");
  const tiempoDeRefresco = localStorage.getItem("tiempoDeRefresco") * 1000;

  useEffect(() => {
    fetchDespachos(); // Load despachos initially
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setModalWidth("360px");
      } else {
        setModalWidth("900px");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const useInterval = (callback, delay) => {
    const savedCallback = React.useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(() => {
    if (shouldFetch) {
      fetchDespachos(); // Solo ejecuta si shouldFetch es true
    }
  }, tiempoDeRefresco);

  const fetchDespachos = async () => {
    setIsLoading(true);
    try {
      const despachos = await Despachos.getDespachos();
      const despachosData = Object.values(despachos.data);
      despachosData.sort((a, b) => {
        const dateA = new Date(a.fecha);
        const dateB = new Date(b.fecha);
        return dateB - dateA;
      });
      setDespachoArray(despachosData);
      setShouldFetch(true); // Permitir futuras llamadas si no hay errores
    } catch (error) {
      apiErrorHandler(error, { stopFlow: true });
      setShouldFetch(false); // Detener futuras llamadas
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = async despacho => {
    if (window.location.pathname === "/dashboard") {
    } else {
      const esPlaya = localStorage.getItem("solicitarAforadores");
      if (esPlaya == "true") {
        navigate("/dashboard");
      } else {
        navigate("/dashboardmini");
      }
    }
    console.log(despacho);

    despacho.idProductoArray = idProductoArray;
    const despachosArray = JSON.parse(localStorage.getItem("despachosSeleccionados")) || [];
    despachosArray.push(despacho.idDespacho);
    localStorage.setItem("despachosSeleccionados", JSON.stringify(despachosArray));

    setIsLoading(true);

    try {
      await estadoDespacho.actualizarDespacho(despacho.idDespacho, 1, 0);
      addProducto(despacho);
    } catch (error) {
      apiErrorHandler(error, { stopFlow: true });
    }
  };

  const handleCancelar = () => {
    window.history.back();
  };

  const handleDespachoManualClick = () => {
    navigate("/despachomanualpage");
  };

  return (
    <>
      <MenuSection />
      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "52%" : "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          outline: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Paper
          sx={{
            height: window.innerWidth < 700 ? 475 : 345,
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#999",
              borderRadius: 2,
            },
            flexGrow: 1,
            boxShadow: 0,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.bandera.colorSecundario,
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid #fff",
                    padding: "3px",
                    fontSize: window.innerWidth > 700 ? "17px" : "11px",
                  }}
                >
                  SURT.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.bandera.colorSecundario,
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid #fff",
                    padding: "3px",
                    fontSize: window.innerWidth > 700 ? "17px" : "11px",
                  }}
                >
                  MANG.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.bandera.colorSecundario,
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid #fff",
                    padding: "3px",
                    fontSize: window.innerWidth > 700 ? "17px" : "11px",
                  }}
                >
                  PROD.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.bandera.colorSecundario,
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid #fff",
                    padding: "3px",
                    fontSize: window.innerWidth > 700 ? "17px" : "11px",
                  }}
                >
                  LITROS
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.bandera.colorSecundario,
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid #fff",
                    padding: "3px",
                    fontSize: window.innerWidth > 700 ? "17px" : "11px",
                  }}
                >
                  IMPORTE
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align="center" sx={{ borderBottom: "none" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8rem" }}>
                      <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {despachoArray
                  .filter(despacho => despacho.idCierreTurno == 0)
                  .slice(0, cantidadAMostrar)
                  .map(despacho => {
                    const cssColor = ""; //argbToCssColor(despacho.colorARGB);
                    return (
                      <TableRow
                        key={despacho.idDespacho}
                        sx={{
                          textDecoration: "none",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            textDecoration: "none",
                          },
                          height: "80px",
                          userSelect: "none",
                        }}
                        onClick={() => handleRowClick(despacho)}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            padding: window.innerWidth > 700 ? "25px" : "5px",
                            fontSize: window.innerWidth > 700 ? "17px" : "14px",
                            backgroundColor: cssColor,
                          }}
                          mx={0.3}
                        >
                          {despacho.cara == "B" ? despacho.idFisicoCaraB + "B" : despacho.idFisicoCaraA + "A"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            padding: "5px",
                            fontSize: window.innerWidth > 700 ? "17px" : "14px",
                            backgroundColor: cssColor,
                          }}
                          mx={0.3}
                        >
                          {despacho.numeroManguera}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            padding: "5px",
                            fontSize: window.innerWidth > 700 ? "17px" : "12px",
                            maxWidth: "90px",
                            backgroundColor: cssColor,
                          }}
                          mx={0.3}
                        >
                          {despacho.descripcionArticulo}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            padding: "5px",
                            fontSize: window.innerWidth > 700 ? "17px" : "14px",
                            backgroundColor: cssColor,
                          }}
                          mx={0.3}
                        >
                          {despacho.cantidad.toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            padding: "5px",
                            fontSize: window.innerWidth > 700 ? "17px" : "14px",
                            backgroundColor: cssColor,
                          }}
                          mx={0.3}
                        >
                          ${despacho.importe.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
          {/* <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<LocalGasStationIcon />}
            onClick={handleDespachoManualClick}
            sx={{
              marginLeft: "8px", 
              boxShadow: 0,
              backgroundColor: "secondary.main",
              ":hover": {
                backgroundColor: "secondary.dark",
              },
            }}
          >
            Despacho Manual
          </Button> */}
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 17,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={() => handleCancelar()}
          >
            CANCELAR
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DespachosSearchPage;
