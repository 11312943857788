import axios from "axios";

const combustible = async idCombustible => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, idCombustible: idCombustible, serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover") };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Mangueras/GetManguerasByCombustible`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const mangueras = {
  combustible,
};

export default mangueras;
