import axios from "axios";

const getEstacion = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Estaciones/GetEstacionLocal`, userData);
  return response;
};

const estacion = {
  getEstacion,
};

export default estacion;
