import React, { useState } from "react";
import { Box, TextField, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";

const FormChoferSinCliente = () => {
  const [nombreChofer, setNombreChofer] = useState("");
  const [numeroVehiculo, setNumeroVehiculo] = useState("");
  const [patente, setPatente] = useState("");
  const [kilometraje, setKilometraje] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCancelar = () => {
    localStorage.removeItem("chofer");
    navigate("/dashboard");
  };

  const handleAceptar = () => {
    const choferData = JSON.parse(localStorage.getItem("chofer")) || {};

    const updatedChoferData = {
      ...choferData,
      nombreChofer: nombreChofer.toUpperCase(),
      numeroVehiculo: numeroVehiculo.toUpperCase(),
      patente: patente.toUpperCase(),
      kilometraje,
    };
    localStorage.setItem("chofer", JSON.stringify(updatedChoferData));
    localStorage.setItem("completoDatosChofer", true);
    console.log(updatedChoferData);
    navigate("/dashboard");
  };

  // Verificar si al menos un input está lleno
  const isButtonDisabled = !nombreChofer && !numeroVehiculo && !patente && !kilometraje;

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: window.innerWidth < 700 ? "90%" : "350px",
          top: window.innerWidth < 700 ? "50%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "auto",
        }}
      >
        <h3 style={{ color: theme.bandera.colorSecundario, marginBottom: "40px" }}>Ingrese los datos solicitados</h3>
        <Paper
          sx={{
            height: "auto",
            boxShadow: 0,
            marginBottom: 2,
          }}
        >
          <TextField
            label="Nombre del chofer"
            value={nombreChofer}
            onChange={e => setNombreChofer(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
          <TextField
            label="N° vehículo"
            value={numeroVehiculo}
            onChange={e => setNumeroVehiculo(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
          <TextField
            label="Patente"
            value={patente}
            onChange={e => setPatente(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
          <TextField
            label="Kilometraje"
            value={kilometraje}
            onChange={e => setKilometraje(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
            type="number"
          />
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: window.innerWidth < 700 ? "center" : "flex-end",
            width: "100%",
            boxSizing: "border-box",
            marginTop: "50px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 17,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleAceptar}
            disabled={isButtonDisabled}
          >
            ACEPTAR
          </Button>
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              marginLeft: "20px",
              fontSize: 17,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleCancelar}
          >
            CANCELAR
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FormChoferSinCliente;
