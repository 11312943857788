import axios from "axios";

const actualizarDespacho = async (idDespacho, elegido, facturado) => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    idDespacho, // Usar el idDespacho pasado como parámetro
    elegido, // Usar el valor de elegido pasado como parámetro
    facturado, // Usar el valor de facturado pasado como parámetro
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Despachos/SeleccionarYFacturarDespacho`, userData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.data;
};

export default { actualizarDespacho };
