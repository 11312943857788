import axios from "axios";

const getSaldo = async idCliente => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const userData = {
    nic: formattedNic,
    token: token,
    idCliente: idCliente,
    idPuesto: puesto.idPuesto,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Clientes/GetSaldoCliente`, userData);
  return response.data;
};

const SaldoCliente = {
  getSaldo,
};

export default SaldoCliente;
