import axios from "axios";

const actualizarVenta = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const idEmpleadoResponsable = localStorage.getItem("idEmpleadoResponsable");
  const idsMovimientosFac = JSON.parse(localStorage.getItem("idsMovimientosFac"));

  const userData = {
    nic: formattedNic,
    token: token,
    idsMovimientosFac: idsMovimientosFac,
    idEmpleadoResponsable: idEmpleadoResponsable,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  console.log(JSON.stringify(userData));
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/ActualizarVentaQR`, {
    userData,
  });
  return response.data;
};

const actualizarVentaQR = {
  actualizarVenta,
};

export default actualizarVentaQR;
