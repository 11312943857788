import axios from "axios";

const getEmpleado = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Vendedores/GetEmpleadosVendedores`, userData);
  return response;
};

const empleado = {
  getEmpleado,
};

export default empleado;
