import axios from "axios";

const postActualizarFirma = async idMovimientoFac => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    idMovimientoFac: idMovimientoFac,
    firma: localStorage.getItem("firma"),
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Facturacion/ActualizarFirmaRemito`, userData);
    if (response.status === 200) {
      console.log("PDF firmado y enviado con éxito:", response.data);
      return true;
    } else {
      console.error("Error al enviar el PDF firmado:", response.data);
      return false;
    }
  } catch (error) {
    throw error;
  }
};

const actualizarFirma = {
  postActualizarFirma,
};

export default actualizarFirma;
