import passEmpleado from "../api/empleado";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

export const hashWithSHA1ToBase64UTF16 = async text => {
  const data = new Uint16Array(text.length);
  for (let i = 0; i < text.length; i++) {
    data[i] = text.charCodeAt(i);
  }

  const buffer = data.buffer;
  const hashBuffer = await crypto.subtle.digest("SHA-1", buffer);
  const hashBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));
  return hashBase64;
};

export const verificarEmpleado = async password => {
  try {
    const resultado = await passEmpleado.getEmpleado();
    const empleados = resultado.data;

    const hashedPassword = await hashWithSHA1ToBase64UTF16(password);

    const empleadoEncontrado = empleados.find(emp => emp.password.toLowerCase() === hashedPassword.toLowerCase());

    if (empleadoEncontrado) {
      return {
        success: true,
        empleado: empleadoEncontrado,
      };
    } else {
      return {
        success: false,
        message: "Contraseña inválida",
      };
    }
  } catch (error) {
    console.error("Error al obtener los empleados:", error);
    apiErrorHandler(error, { stopFlow: true });
    return {
      success: false,
      message: "Error al obtener los empleados",
    };
  }
};
