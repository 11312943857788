import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FiAlertCircle } from "react-icons/fi";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";
import venta from "../api/venta";
import actualizarVentaQR from "../api/actualizarVentaQR";
import generarFacturaJson from "../helpers/generarFacturaJson";
import {
  inicializarCanvas,
  agregarEncabezado,
  agregarDatosCliente,
  agregarArticulosYDespachos,
  agregarImpuestos,
  agregarTitulo,
  agregarNombreChofer,
} from "../helpers/facturaUtils";
import actualizarFirma from "../api/actualizarFirma";

function TicketCanvas() {
  const navigate = useNavigate();
  const location = useLocation();
  const CuponClover = JSON.parse(localStorage.getItem("CuponClover"));
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const imprimir = location.state?.imprimir;
  const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");
  const [showError, setShowError] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const theme = useTheme();
  const pagoConQR = localStorage.getItem("pagoConQR");

  useEffect(() => {
    // const processCompleted = localStorage.getItem("processCompleted");
    // if (imprimirFAA) {
    //   localStorage.setItem("processCompleted", "true");
    //   generateCanvasContent();
    // } else if (processCompleted === "true") {
    //   navigate("/finalizarPago");
    // } else {
    //   localStorage.setItem("processCompleted", "true");
    generateCanvasContent();
    // }
  }, []);


  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    // Función para manejar el evento cuando se presiona el botón de retroceso
    // Aquí puedes redirigir a otra página o simplemente hacer nada
    navigate("/ticket"); // Redirecciona a otra página según sea necesario
  };

  const handleVenta = async () => {
    try {
      const response = await venta.postVenta();
      return response.data;
    } catch (error) {
      console.error("Error en handleVenta:", error);
    }
  };

  const handleAlertClose = () => {
    setShowAlertDialog(false);
    navigate("/finalizarPago");
  };

  const handleRetry = () => {
    setShowError(false);
    generateCanvasContent();
  };

  const generateCanvasContent = async () => {
    const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
    const estacionData = JSON.parse(localStorage.getItem("estacionData"));
    const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
    const prefijo = localStorage.getItem("prefijoFront") || null;
    const user = localStorage.getItem("user");
    const clienteString = localStorage.getItem("cliente");
    const cliente = clienteString ? JSON.parse(clienteString) : null;

    let facturaFinal;

    try {
      const facturaJson = generarFacturaJson(CuponClover, puesto, cliente, facturaInfo, totalProductos, prefijo, emiteFacturayRemito);
      console.log(JSON.stringify(facturaJson));
      localStorage.setItem("facturaJson", JSON.stringify(facturaJson));

      if (pagoConQR) {
        facturaFinal = await actualizarVentaQR.actualizarVenta();
      } else {
        facturaFinal = await handleVenta();
      }

      localStorage.setItem("facturaFinal", JSON.stringify(facturaFinal));

      console.log(facturaFinal);

      const idMovimientos = facturaFinal.respuestas.map(movimiento => movimiento.idMovimientoFac);
      localStorage.setItem("idMovimientoFac", JSON.stringify(idMovimientos));
    } catch (error) {
      console.error("Error en generateCanvasContent:", error);

      const retry = localStorage.getItem("reintentos");
      if (retry === "0" || retry === null) {
        setAlertMessage("ERROR al establecer conexion con afip, no se puede emitir comprobante");
        setShowAlertDialog(true);
      } else {
        setShowError(true);
      }
      return;
    }

    if (facturaInfo) {
      const canvas = inicializarCanvas(550, 2000);
      let y = 30;

      y = agregarEncabezado(canvas, estacionData.data, "fechaActual", "horaActual", y);

      if (!cliente || cliente.categoriaIVA.abreviaturaCategoriaIVA === "CF" || cliente.categoriaIVA.abreviaturaCategoriaIVA === "EX") {
        y = agregarTitulo(canvas, "FACTURA", "COD.06", prefijo, facturaFinal?.respuestas[0]?.numero, y);
      } else if (cliente.categoriaIVA.abreviaturaCategoriaIVA === "RI" || cliente.categoriaIVA.abreviaturaCategoriaIVA === "MO") {
        y = agregarTitulo(canvas, "FACTURA", "COD.01", prefijo, facturaFinal?.respuestas[0]?.numero, y);
      }

      y = agregarDatosCliente(canvas, cliente, y);
      y += 20;
      y = agregarNombreChofer(canvas, y);
      y += 20;
      y = agregarArticulosYDespachos(canvas, facturaInfo, y);
      y = agregarImpuestos(canvas, facturaFinal.respuestas[0], totalProductos, "A", null, y, facturaInfo);

      localStorage.setItem("canvasHeight", y + 500);

      if (imprimir === true) {
        navigate("/comprobante");
      } else {
        navigate("/finalizarPago");
      }
    }
  };

  return (
    <div>
      <MenuSection />
      {showError && (
        <Dialog
          open={showError}
          sx={{
            "& .MuiDialog-paper": {
              padding: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[5],
              borderRadius: theme.shape.borderRadius,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: theme.spacing(2),
            }}
          >
            <FiAlertCircle size={48} color={theme.palette.info.main} />
          </Box>
          <DialogTitle>{"ERROR: AL ENVIAR LA TRANSACCIÓN"}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "1.25rem", textAlign: "left", width: "100%" }}>
              {localStorage.getItem("errorMensaje") || "La transacción ha superado el tiempo de espera. Intente nuevamente"}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-end", fontWeight: "bold" }}>
            <Button onClick={handleRetry} color="primary" variant="contained">
              <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                Reintentar
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showAlertDialog && (
        <Dialog
          open={showAlertDialog}
          onClose={handleAlertClose}
          sx={{
            "& .MuiDialog-paper": {
              padding: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[5],
              borderRadius: theme.shape.borderRadius,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: theme.spacing(2),
            }}
          >
            <FiAlertCircle size={48} color={theme.palette.info.main} />
          </Box>
          <DialogTitle>{"ERROR: AL ENVIAR LA TRANSACCIÓN"}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "1.25rem", textAlign: "left", width: "100%" }}>{alertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-end", fontWeight: "bold" }}>
            <Button onClick={handleAlertClose} color="primary" variant="contained">
              <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                Aceptar
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box className="printing-container" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress color="primary" size={50} />
        <Typography variant="body1" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Procesando la transacción...
        </Typography>
      </Box>
    </div>
  );
}

export default TicketCanvas;
