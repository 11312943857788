export function guardarTalonarios(puesto) {
    if (puesto && puesto.talonarios) {
      puesto.talonarios.forEach(talonario => {
        talonario.talonarioDocumentos.forEach(documento => {
          switch (documento.idTipoMovimiento) {
            case "REM":
              localStorage.setItem("idTalonarioREM", talonario.idTalonario);
              localStorage.setItem("puntoVentaREM", talonario.puntoVenta);
              localStorage.setItem("cantidadCopiasREM", talonario.copias);
              break;
            case "FAB":
              localStorage.setItem("idTalonarioB", talonario.idTalonario);
              localStorage.setItem("puntoVentaB", talonario.puntoVenta);
              localStorage.setItem("cantidadCopiasFAB", talonario.copias);
              break;
            case "FAA":
              localStorage.setItem("idTalonarioA", talonario.idTalonario);
              localStorage.setItem("puntoVentaA", talonario.puntoVenta);
              localStorage.setItem("cantidadCopiasFAA", talonario.copias);
              break;
            default:
              break;
          }
        });
      });
    } else {
      console.error("No se encontró el objeto 'puesto' o la propiedad 'talonarios' dentro de él.");
    }
  }
  