export const calcularTotal = productos => {
  let total = 0;
  productos.forEach(producto => {
    total += producto.importe;
  });
  return total.toFixed(2);
};

export const combinarArticulos = articulos => {
  return articulos.reduce((acc, articulo) => {
    const existingArticulo = acc.find(item => item.idArticulo === articulo.idArticulo);
    if (existingArticulo) {
      existingArticulo.cantidadArticulo += articulo.cantidadArticulo;
      existingArticulo.importe += articulo.importe;
    } else {
      acc.push({ ...articulo });
    }
    return acc;
  }, []);
};

export const limpiarDespachos = async (estadoDespacho, despachosArray) => {
  for (let despacho of despachosArray) {
    try {
      await estadoDespacho.actualizarDespacho(despacho, 0, 0);
    } catch (error) {
      console.error("Error al actualizar el despacho:", error);
    }
  }
  localStorage.removeItem("despachosSeleccionados");
};

export const handleDeleteRow = async (id, productos, updateProducto, estadoDespacho) => {
  // Encuentra el producto a eliminar
  const productoAEliminar = productos.find(producto => producto.idProductoArray === id);

  if (productoAEliminar) {
    // Filtra los productos para excluir
    const newState = productos.filter(producto => producto.idProductoArray !== id);

    // Usa updateProducto para actualizar el estado con la nueva lista sin el producto eliminado
    updateProducto(newState);

    if (productoAEliminar.idDespacho) {
      // Gestiona el despacho si es necesario
      const despachosArray = JSON.parse(localStorage.getItem("despachosSeleccionados")) || [];
      const despachoIndex = despachosArray.indexOf(productoAEliminar.idDespacho);

      if (despachoIndex !== -1) {
        despachosArray.splice(despachoIndex, 1);
        localStorage.setItem("despachosSeleccionados", JSON.stringify(despachosArray));
      }

      // Actualiza el estado del despacho
      try {
        await estadoDespacho.actualizarDespacho(productoAEliminar.idDespacho, 0, 0);
      } catch (error) {
        console.error("Error al actualizar el despacho:", error);
      }
    }
  }
};
