import axios from "axios";

const getEmpleadosActivos = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Vendedores/GetEmpleadosActivos`, userData);
  return response.data;
};

const empleados = {
  getEmpleadosActivos,
};

export default empleados;
